html, body, #root {
    height: 100%;
}

.input-container {
    max-width: 500px;
    margin: 50px auto 50px auto;
    padding: 30px 30px 30px 30px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .input-container {
        max-width: 768px;
        width: 100%;
        margin: 0 0 0 0;
        height: 100%;
    }
}

.input-form-element {
    width: 100%;
}

.loading-container {
    max-width: 300px;
    margin: 50px auto 50px auto;
    padding: 30px 30px 30px 30px;
    text-align: center;
}